import { WrapperContent, WrapperSubContent, WrapperTitle } from "../../../../Shared/React/components/styles/Wrappers";
import { Table, TableFilter } from "../../../../Shared/React/components/styles/Tables";
import { SyncData } from "./SyncData";
import { Link, useParams } from "react-router-dom";
import {  Button, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";
import { term_data_name } from "./SyncList";
import { CheckCircleIcon, ExclamationIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { EnlistmentReportService } from "../../Domain/Services/SyncApplicationServices";
import { useEffect, useState } from "react";
import { EnlistmentReport } from "../../Domain/Interfaces";
import { http } from "../../../../Shared/Http/http";


export const SyncReportDetails = () => {

const {idDetail}=useParams();

const [SyncData, setSyncData]:any = useState([]);

let crearData:any
let asociarData:any
let headerData:any

useEffect(() => {
  EnlistmentReportService(String(idDetail)).then(
    response => {
     console.log(response.messages);
      setSyncData(JSON.parse(response.messages));

  })
}, [idDetail])

    crearData = SyncData.crear;
    asociarData = SyncData.asociar;
    headerData = SyncData.header;

  if (!crearData || !asociarData) {
    return <WrapperContent>
    <WrapperTitle>Archivo de Alistamiento</WrapperTitle>
    <WrapperSubContent className="mb-5">
                <div className="w-full text-center text-2xl font-bold pb-2">
                <div className=' rounded-md bg-primary-500 text-white p-2 mb-2'>Sin archivo leible</div>
                </div>

                <div className="justify-around flex w-full text-center text-md pt-2">
                    <div className="text-center w-1/3">Registros Leidos</div>
                    <div className="text-center w-1/3">Numero de Grupos</div>
                    <div className="text-center w-1/3">Numero de Cursos</div>
                </div>
                <div className="justify-around flex w-full text-2xl text-center font-bold pb-5">
                    <div className="text-center w-1/3">0</div>
                    <div className="text-center w-1/3">0</div>
                    <div className="text-center w-1/3">0</div>
                </div>
                <hr className="h-2 bg-black border-0 rounded-xl" />

                <div className="justify-around flex w-full text-2xl text-center font-bold pt-5">
                    {/*<div className="text-center">Resultado:{crearData.reponse_crear.message}</div>*/}
                </div>
            </WrapperSubContent>
            
            <div className='my-7 text-right'>
            <div className="text-3xl font-black absolute">Datos Procesados</div>
                    <LinkPrimary to={'./../'}>Volver</LinkPrimary>
            </div>
</WrapperContent>;
  }

  return (
    <WrapperContent>
        <WrapperTitle>Archivo de Alistamiento</WrapperTitle>
        <WrapperSubContent className="mb-5">
                    <div className="w-full text-center text-2xl font-bold pb-2">
                    <div className=' rounded-md bg-primary-500 text-white p-2 mb-2'>{headerData.tittle_file}</div>
                    </div>

                    <div className="justify-around flex w-full text-center text-md pt-2">
                        <div className="text-center w-1/3">Registros Leidos</div>
                        <div className="text-center w-1/3">Numero de Grupos</div>
                        <div className="text-center w-1/3">Numero de Cursos</div>
                    </div>
                    <div className="justify-around flex w-full text-2xl text-center font-bold pb-5">
                        <div className="text-center w-1/3">{headerData.total_registros_leer}</div>
                        <div className="text-center w-1/3">{headerData.total_grupos_leer}</div>
                        <div className="text-center w-1/3">{headerData.total_cursos_leer}</div>
                    </div>
                    <hr className="h-2 bg-black border-0 rounded-xl" />

                    <div className="justify-around flex w-full text-2xl text-center font-bold pt-5">
                        {/*<div className="text-center">Resultado:{crearData.reponse_crear.message}</div>*/}
                    </div>
                </WrapperSubContent>
                
                <div className='my-7 text-right'>
                <div className="text-3xl font-black absolute">Datos Procesados</div>
                        <LinkPrimary to={'./../'}>Volver</LinkPrimary>
                </div>
                
        <Table>
            
            <tr>
                    <th className="w-1">Fila</th>
                    <th>Asignatura</th>
                    <th>Estado</th>
                    <th>Grupo</th>
                    <th>Estado</th>
            </tr>
            <tbody>
      {Object.keys(crearData || asociarData).map((key) => {
        const item:any = crearData[key];
        const resp:any = asociarData[key];
         // Verificar si resp tiene un valor definido
        if (!resp) {
            return null; // O puedes manejar el caso en el que resp es undefined de alguna otra manera
        }

        return (
            <>
                
                    <tr className="w-1 text-center">
                        <td rowSpan={3}>{item.fila}</td>
                    </tr>
                    <tr>
                        <td>{item.codigo_asignatura}</td>
                        <td className="w-1 text-center" rowSpan={2}>
                        {item.status === 'error' ? <ExclamationIcon className="text-red-600"><title>{item.status}</title></ExclamationIcon> : <CheckCircleIcon className=' text-green-500'><title>{item.status}</title></CheckCircleIcon> }
                        </td>
                        <td>{resp.codigo_grupo}</td>
                        <td className="w-1 text-center" rowSpan={2}>
                        {resp.status === 'error' ? <ExclamationIcon className="text-red-600"><title>{resp.status}</title></ExclamationIcon> : <CheckCircleIcon className=' text-green-500'><title>{resp.status}</title></CheckCircleIcon> }
                        </td>
                    </tr>
                    <tr>
                        <td>{item.mssg}</td>
                        <td>{resp.mssg}</td>
                    </tr>
                    
                    <tr>
                        <td className="bg-gray-300 h-0"/>
                        <td className="bg-gray-300  h-0"/>
                        <td className="bg-gray-300 h-0"/>
                        <td className="bg-gray-300 h-0"/>
                        <td className="bg-gray-300 h-0"/>
                    </tr>
          </>
        );
      })}
      </tbody>
      </Table>
    </WrapperContent>
  );


};
