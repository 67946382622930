import { useEffect, useState, useContext} from 'react'
import { WrapperContent, WrapperTitle } from '../../../../Shared/React/components/styles/Wrappers';

import { DataTableStyle } from '../../../../Shared/React/components/styles/DataTableStyle';
import { TableFilter } from '../../../../Shared/React/components/styles/Tables';

import { CorsCourses} from '../../Domain/Interfaces';

import { ButtonPrimary, LinkPrimary } from "../../../../Shared/React/components/styles/Buttons";

import { CorsCoursesListService, CorsCoursesDisableService, downloadAllCourses } from '../../Domain/Services/CorsCoursesApplicationServices';
import { AuthContext } from '../../../../Auth/Infrastructure/context/AuthContext';
import { DocumentDownloadIcon, DocumentReportIcon, EyeIcon, FolderRemoveIcon, LinkIcon, PencilIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { INITIAL_VALUE_FIRST_LOG } from '../../../User/Domain/Types';
import { UserFirstLog } from '../../../User/Domain/Interfaces';
import { UserListByIdService } from '../../../User/Domain/Services/UserApplicationServices';
import { ValidatorBox } from '../../../../Shared/React/components/field/ValidatorBox';

export const CorsCoursesList = () => {

    const [courses, setCourses] = useState<CorsCourses[]>([]);

    const { authState, isAdmin, isManager,isMonitor, getRoles } = useContext( AuthContext );

    const CourseDisable = (id: string) => {
        CorsCoursesDisableService(id).then((result) => { 

            setCourses(prev => prev.filter(course => course.id !== id));
             try {
                  toast.info('Se ha deshabilitado un Curso',
                      {
                      position: 'bottom-center',
                      closeButton: false,
                      theme: 'colored', hideProgressBar: true, autoClose: 2500
                      }
                  )}
              catch{}
        })
      }

      const [showText, setShowText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowText(true);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);

      const downloadAll = () => {
        console.log('result');
        downloadAllCourses().then((result) => {
            window.open(process.env.REACT_APP_URL_SEE_FILES + result.path)
        })
    }

    useEffect(() => {
        //@ts-ignore
        CorsCoursesListService(getRoles(), authState.user?.id).then(setCourses)
    }, [])

    const CorsCoursesItem =
    [
        {
            name: 'Código Institucional',
            selector: (row:any) => row.code,
            cell: (row:any) => row.code,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Estado',
            selector: (row:any) => row.status.name,
            cell: (row:any) => row.status.name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Nombre Curso',
            selector: (row:any) => row.name,
            cell: (row:any) => row.name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        {
            name: 'Docente',

            
            selector: (row:any) => row.user.name,
            
            cell: (row:any) => row.user.name,
            sortable: true,
                reorder: true,
            filterable: true,
            marginBottom: '10px',
        },
        { 
            name: 'Acciones',
            width:'12%',
            reorder: true,
            center: true,
            verticalAlign: 'middle',
            selector: (row:any) => row.name,
            cell: (row:any) => 
            <div className='flex'>

                <Link title='Ver detalle de curso' to={ row.id+"/detail/"}>
                    <EyeIcon className='text-primary-900' height='28px'/>
                </Link>

                {row.user.name === '' ? 
                <button title='No es posible agregar revisiones sin profesor asignado'>
                    <DocumentReportIcon className='text-gray-400 cursor-not-allowed' height='28px'/>
                </button>:
                <Link title='Ver revisiones de un curso' to={row.id+"/review/"}>
                    <DocumentReportIcon className='text-primary-900' height='28px'/>
                </Link>
                }

                 
                
                <a title='Ver url del curso' href={row.url} target="_blank" rel="noreferrer"> 
                    <LinkIcon className='text-primary-900' height='28px'></LinkIcon>
                </a>

                {(isAdmin() || isManager() || isMonitor()) ?
                <Link title='Editar un Curso' to={"edit/" + row.id}>
                    <PencilIcon className='text-primary-900' height='28px'/>
                </Link>
                :''
                }

                {isAdmin() ?
                <button title='Deshabilitar un curso'  data-bs-toggle="modal" data-bs-target={"#disableCourse-"+row.id}>
                    <FolderRemoveIcon className='text-primary-900' height='28px' />
                </button>
                :''
                }

                <ValidatorBox
                        id={row.id}
                        name={row.name}
                        actionname="disableCourse"
                        service={CourseDisable}
                        action="deshabilitar el curso"
                        />
                    
            </div>
      },
    ]

    
  return (
        <>

        <ToastContainer/>
        <WrapperTitle>
            Cursos CORE
        </WrapperTitle>
        <WrapperContent>
        <div className='text-right mb-8 mt-2 flex justify-end'>
                    {/*<ButtonPrimary onClick={downloadAll}><DocumentDownloadIcon height={'1.5rem'}/>&nbsp;&nbsp;Descargar cursos</ButtonPrimary>*/}
        {(isAdmin() || isMonitor()) ?
                
                    <LinkPrimary to='create'>Agregar un Curso</LinkPrimary>
                
               :''
            }
            </div>

                {courses[0] === void[]
             ? 

              showText ? (
                <div className='w-full items-center text-center justify-center bg-white p-5 rounded-xl'>No hay cursos creados para mostrar<br/>o la respuesta del servidor esta tardando mas de lo esperado</div>
              ) : (
                <div><img className="w-full h-48" src="/images/loading.svg" alt=""/></div>
              )   
            :
                <TableFilter 
                  columns={CorsCoursesItem}
                  data={courses}
                  customStyles={DataTableStyle}
                  pagination
                  responsive
                  striped
                  highlightOnHover
              />}
        </WrapperContent>
        </>
    )
}